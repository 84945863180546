export default function (Component) {
      Component.options.frontmatter = {
  "published": "2019-06-03T00:00:00.000Z",
  "tags": [
    "design",
    "color",
    "eli5"
  ],
  "image": require('./dark.png'),
  "title": "Finally some colors in my life",
  "excerpt": "<p>&quot;I got a red shirt!&quot;, I scream, every time my friends jab that my wardrobe is grey and dull. They always call me out, &quot;Get some colors, dude!&quot;.</p>\n"
}
    }